import axios from "./axios";

import { PartnerUser, ScopeUser, UpdateUserRolePayload } from "@/typings";

export default {
  getPartnerUsers: (partnerName: string) =>
    axios.get<PartnerUser[]>(`/v1/user/partner_users/${partnerName}`),

  getEndUsers: (partnerName: string) => {
    return axios.get(`/v1/user/partner_users/${partnerName}`, { params: { command: "end_users" } });
  },

  createUser: (user: PartnerUser | ScopeUser) => axios.post("/v2/user/users", user),

  deletePartnerUser: (partnerName: string, users: string[]) => {
    return axios.patch(`/v1/partners/${partnerName}`, {
      action: "delete_users",
      users,
      partner: true,
    });
  },

  updateSearchHistory: (value: { type: string; entries: string[] }) => {
    return axios.patch("/v2/user/users", { value, command: "update_search_history" });
  },
};

export const updateUserRoles = (payload: UpdateUserRolePayload, partner_name: string) => {
  return axios.patch(`/v1/user/${payload.username}/roles`, {
    roles: payload.roles,
    scope: payload.scope,
    subscope: payload.subscope,
    partner_name: partner_name,
  });
};

export const removeAllUserAccesses = (payload: { partner_name: string; username: string }) => {
  return axios.patch(`/v1/user/partner_users/${payload.partner_name}`, {
    username: payload.username,
  });
};
