<template>
  <v-dialog :model-value="isVisible" :width="_width" scrollable persistent>
    <v-card id="sw-details-dialog">
      <v-card-title
        class="primary d-flex justify-space-between"
        :class="{ 'flex-column': $vuetify.display.smAndDown }"
      >
        <div class="d-flex align-center">
          <template v-if="!$slots['header.left']">
            <v-icon v-if="icon" size="small" class="mr-1">{{ icon }}</v-icon>
            {{ title }}
          </template>

          <slot v-else name="header.left" />
        </div>

        <slot name="header.right" />

        <v-progress-circular v-if="isLoading" indeterminate width="2" height="2" size="16" />
      </v-card-title>

      <v-card-text :class="`${hasSteps ? 'pa-0' : 'pt-5'}`">
        <v-stepper v-if="hasSteps" v-model:model-value="currentStep" flat hide-actions>
          <v-stepper-header>
            <!-- currentStep in state and step property of the v-stepper-content needs to macth. 
              This is why we user idx+1 as the step property-->
            <v-stepper-item
              v-for="(step, idx) in steps"
              :key="`${idx}-step`"
              :title="step.title"
              :value="idx + 1"
              :complete="currentStep > idx + 1"
            />
          </v-stepper-header>

          <v-stepper-window>
            <v-stepper-window-item
              v-for="(step, idx) in steps"
              :value="idx + 1"
              :key="`${idx}-content`"
            >
              <slot :name="`step.${step.value}`" :key="idx" />
            </v-stepper-window-item>
          </v-stepper-window>
        </v-stepper>

        <slot v-else />
      </v-card-text>

      <v-divider />

      <sw-card-actions
        :cancel-text="cancelText"
        :submit-text="_submitText"
        :cancel-disabled="isLoading"
        :submit-disabled="isDisabled"
        :submit-loading="isLoading"
        :cancel-visible="cancelVisible"
        :submit-visible="submitVisible"
        @click:submit="onSubmitClick"
        @click:cancel="onClose"
      >
        <slot name="extra-action" />
      </sw-card-actions>
    </v-card>
  </v-dialog>
</template>

<script lang="ts">
  // @ts-nocheck
  import { defineComponent } from "vue";
  import { mapActions, mapGetters } from "vuex";
  import { SwCardActions } from "sweepatic-shared/components";

  interface Methods {
    onSubmitClick: () => void;
    onClose: () => void;
    closeDialog: () => void;
    goPreviousStep: () => void;
  }

  interface Computed {
    isVisible: boolean;
    isLoading: boolean;
    isDisabled: boolean;
    hasSteps: boolean;
    _width: string;
    _submitText: string;
  }

  interface Step {
    text: string;
    value: string;
  }

  interface Props {
    icon: string;
    title?: string;
    cancelText?: string;
    submitText?: string;
    cancelVisible?: boolean;
    submitVisible?: boolean;
    steps?: Step[];
    width?: string;
  }

  export default defineComponent<{}, Methods, Computed, Props>({
    name: "sw-form-dialog",
    components: { SwCardActions },
    props: {
      title: { type: String, default: null },
      icon: { type: String, default: null },
      cancelText: { type: String, default: "Close" },
      submitText: { type: String, default: "Submit" },
      cancelVisible: { type: Boolean, default: true },
      submitVisible: { type: Boolean, default: true },
      steps: { type: Array, default: null },
      width: { type: String, default: null },
    },
    computed: {
      ...mapGetters("formDialog", ["isLoading", "isDisabled", "isVisible", "currentStep"]),
      hasSteps() {
        return this.steps?.length > 0;
      },
      _width() {
        if (this.$vuetify.display.mdAndUp) {
          return this.width || "60%";
        }

        return "auto";
      },
      _submitText() {
        if (!this.hasSteps) {
          return this.submitText;
        }

        return this.steps.length === this.currentStep ? "Submit" : "Next";
      },
    },
    methods: {
      ...mapActions("formDialog", ["closeDialog"]),
      onSubmitClick() {
        if (this._submitText === "Submit") {
          this.$emit("click:submit");
        } else {
          this.$emit("click:next");
        }
      },
      onClose() {
        this.closeDialog();
        this.$emit("click:cancel");
      },
      goPreviousStep() {
        this.$emit("click:previous");
      },
    },
  });
</script>

<style lang="scss" scoped>
  .v-dialog {
    max-height: 60%;

    .v-card-title {
      height: 48px;
      font-size: 16px;
      line-height: initial;

      i,
      span {
        color: #fff;
      }
    }

    .v-card-text {
      overflow: auto;
    }
  }
</style>
