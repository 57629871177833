import UsersService, { updateUserRoles, removeAllUserAccesses } from "@/services/users.service";
import type { Getters, Mutations, Actions } from "@/typings/store";

import type {
  EndUserAccess,
  PartnerUsersState,
  UpdateUserRolePayload,
} from "@/typings/store/users";

const state: PartnerUsersState = {
  partnerUsers: [],
  endUsers: [],
  selectedUser: null,
};

const getters: Getters<PartnerUsersState> = {
  partnerUsers: (state) => state.partnerUsers,
  partnerUsersList: (state) => {
    return state.partnerUsers.map((partner) => {
      return { title: partner.fullname, value: partner.email };
    });
  },
  endUsers: (state) => state.endUsers,
  selectedUser: (state) => state.selectedUser,
};

const mutations: Mutations<PartnerUsersState> = {
  setPartnerUsers(state, payload) {
    state.partnerUsers = payload;
  },

  deletePartnerUser(state, usersToBeDeleted) {
    state.partnerUsers = state.partnerUsers.filter(
      (user) => !usersToBeDeleted.includes(user.email),
    );
  },

  addPartnerUser(state, partnerUser) {
    state.partnerUsers.push(partnerUser);
  },

  setEndUsers(state, endUsers) {
    state.endUsers = endUsers;
  },

  removeUser(state, email) {
    state.endUsers = state.endUsers.filter((user) => user.email !== email);
  },

  setSelectedUser(state, user) {
    state.selectedUser = user;
  },

  updateUser(state, payload: { email: string; access: EndUserAccess[] }) {
    state.endUsers = state.endUsers.map((user) => {
      if (user.email === payload.email) {
        user.access = payload.access;
        return user;
      }
      return user;
    });
    state.selectedUser!.access = payload.access;
  },
};

const actions: Actions<PartnerUsersState> = {
  async getPartnerUsers({ commit, state }, partnerName) {
    if (!state.partnerUsers.length) {
      commit("setPartnerUsers", await UsersService.getPartnerUsers(partnerName));
    }
  },

  async deletePartnerUser({ commit }, payload) {
    await UsersService.deletePartnerUser(payload.partner, payload.usersToBeDeleted);

    commit("deletePartnerUser", payload.usersToBeDeleted);
  },

  async createPartnerUser({ commit }, payload) {
    await UsersService.createUser(payload);

    payload.fullname = `${payload.firstName} ${payload.lastName}`;
    commit("addPartnerUser", payload);
  },

  async getEndUsers({ commit }, partner_name) {
    commit("setEndUsers", await UsersService.getEndUsers(partner_name));
  },

  async removeUserRole({ commit, rootGetters }, payload: UpdateUserRolePayload) {
    await updateUserRoles(payload, rootGetters["session/partner"]);
  },

  async removeAllUserAccesses({ commit }, payload: { partner_name: string; username: string }) {
    await removeAllUserAccesses(payload);
    commit("removeUser", payload.username);
  },
};

export default { namespaced: true, state, getters, mutations, actions };
