import type { KeyObject } from "sweepatic-shared/typings/misc";

import { ModuleType } from "@/typings";

export const CONFIGURABLE_MODULES = [ModuleType.easm, ModuleType.credentials, ModuleType.outscan];
export const EDITABLE_MODULES = [ModuleType.credentials, ModuleType.outscan];

export const licenseDurations: KeyObject[] = [
  { value: "month", title: "1 Month" },
  { value: "year", title: "1 Year" },
];

export const MODULE_LOGO: Record<ModuleType, string> = {
  files: "/assets/img/modules/file-regular.svg",
  easm: "/assets/img/modules/easm.png",
  credentials: "/assets/img/modules/credentials.svg",
  outscan: "/assets/img/modules/outscan.avif",
};
