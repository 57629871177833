<template>
  <v-app v-if="isSessionFetched">
    <sw-navbar v-if="showMenu" />

    <main id="main-container">
      <router-view />
    </main>

    <sw-loading v-if="isLoading" class="no-menu" />

    <sw-snackbar />
  </v-app>

  <sw-loading v-else />
</template>

<script setup lang="ts">
  import { onBeforeMount, computed, ref } from "vue";
  import { useRoute, useRouter } from "vue-router";

  import { useStore } from "@/composables/store";
  import SwNavbar from "@/components/layout/menu/SwNavbar.vue";
  import SwSnackbar from "@/components/common/SwSnackbar.vue";
  import SwLoading from "@/components/common/SwLoading.vue";
  import { getHubspotToken } from "@/services/hubSpot.service";
  import { captureMessage } from "@/services/sentry.service";

  const $store = useStore();
  const $route = useRoute();
  const $router = useRouter();

  onBeforeMount(async () => {
    // there's currently an issue with chunk loading in bundlers (happens in webpack, vite, rollup, etc)
    // to handle cache / cache busting in these files, when the files are built a hash is appended
    // to the file name, e.g.: app-Da_kRIfR.js. Since this hash changes with every build,
    // browser will see this as different files and work it's magic.
    // However, sometimes there's still the change the vite tries to lazy import files that
    // don't exist anymore because they were part of a previous deploy (guessing because of some caching)
    // this is an on going issue for a long time, there's no clear one right solution.
    // possible solutions are: trigger a reload, use service worker to overwrite some caching mechanism,
    // removing the hashing (which can have some caching related issues)
    //
    // I (tiago) decided to go for a page reload as seems to be the easiest one and it's not really
    // something that happens that often for us. So whenver a "dynamic file import" error occurs
    // we'll just trigger a page reload which should hopefully fix it.
    //
    // megathread on vite about this issue: https://github.com/vitejs/vite/issues/11804
    window.addEventListener("vite:preloadError", () => window.location.reload());

    await $store.dispatch("session/getSession");

    if ($store.getters["session/isSessionFetched"]) {
      shouldShowMenu();
      return;
    }

    if (!["admin", "partner"].includes($store.getters["session/account_type"])) {
      shouldShowMenu();
      $router.replace("no-access");
      return;
    }

    if (import.meta.env.PROD) {
      // sometimes hubspot script takes longer to load
      // this interval ƒorces a check for hubspot script existance
      // and only tries to load widger when it exists
      const hubspotSetup = setInterval(async () => {
        if (!window.HubSpotConversations) {
          return;
        }

        try {
          const result = await getHubspotToken();

          if (!result) {
            throw Error("Couldn't init");
          }

          window.hsConversationsSettings = {
            identificationEmail: result.email,
            identificationToken: result.token,
          };

          // sometimes widget fails to load for whatever reason
          // doesn't really seem to be our fault so there's no need
          // to report these issues to sentry
          window.HubSpotConversations.widget.load();
        } catch (err) {
          captureMessage(`Hubspot: ${err}`);
        }

        clearInterval(hubspotSetup);
      }, 5000);
    }

    shouldShowMenu();
  });

  const showMenu = ref(false);

  const isSessionFetched = computed(() => $store.state.session.isSessionFetched);
  const isLoading = computed(() => $store.state.loading.isLoading);

  const shouldShowMenu = () => {
    showMenu.value = !["no-connection", "no-access"].includes($route.name as string);
  };
</script>

<style lang="scss" scoped>
  #main-container {
    flex: 1 1 auto;
    max-width: 100vw;
    margin-top: 48px;
    height: calc(100vh - 48px);
    overflow-x: hidden;
    overflow-y: auto;
    background-color: $gray_50;
  }
</style>
